/**
 * Type: ページ
 * What: ホームランシアターのご案内
 */
import React from 'react';
import Layout from '../../../components/Layout';
import SEO from '../../../components/Seo';
import BreadcrumbComponent from '../../../components/Breadcrumb';
import SimpleSwiper from '../../../components/Slider.fade';
import Image from '../../../util/Image';

const pageTitle = 'ホームランシアターのご案内';
const pageDescription = '包み込むような３面の大型モニターで王さんの868号までの軌跡を臨場感たっぷりに見ていただけます。';
const pageSlug = 'obm';
const pageLogo = 'logo-obm-single.png';

const sliderDataobj = [
  {
    filename: 'main-history-theater.jpg',
  },
];

// Data Props Template
const Template = () => (
  <Layout pageSlug={pageSlug} bg="bg-4">
    <SEO
      title={pageTitle}
      description={pageDescription}
    />
    <BreadcrumbComponent
      hierarchy={4}
      bread2url="obm/"
      bread2title="王貞治ベースボールミュージアム Supported by DREAM ORDER・89パーク・スタジオ"
      bread3url="obm/museum/"
      bread3title="王貞治ヒストリーゾーン"
      bread4title={pageTitle}
    />
    <section className="pageMain bg-12 bs-4">
      <div className="kv-wrap bg-4">
        <div className="kv-inner">
          <SimpleSwiper data={sliderDataobj} />
        </div>
        <div className="container">
          <div className="inner">
            <div className="pageMain-header">
              <div className="floorNo c-4">
                <span>4</span>
                <span>F</span>
              </div>
              <h3 className="ttl">
                <span className="logo-under ml-0">
                  <span className='ml-0'>
                    <b className="is-1">ホームランシアター</b>
                  </span>
                </span>
              </h3>
            </div>
            <div className="overview">
              <p className="text c-4">
                包み込むような３面の大型モニターで王さんの868号までの軌跡を臨場感たっぷりにご覧いただけます。

              </p>
              <p className="list-mark">
                <br />
                <span className="attention">※</span>
                修学旅行などの教育プログラム向けの映像も別途ご準備しております。
                <br />
                <br />
                広さ：58.9㎡
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*
      <section className="section sc-museum bg-12" id="museum">
        <div className="container">
          <div className="inner">
            <h3 className="title is-3 has-text-align-center">History Zone</h3>
            <div className="sc-columns">
              <div className="col col-6 floorbc4">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <div className="col-inner">
                    <div className="col-image">
                      <figure className="image">
                        <Image filename="now-printing.jpg" />
                      </figure>

                    </div>
                    <div className="col-content">
                      <h3 className="ttl c-4 has-text-align-center">タイトルタイトル</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-6 floorbc4">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <div className="col-inner">
                    <div className="col-image">
                      <figure className="image">
                        <Image filename="now-printing.jpg" />
                      </figure>

                    </div>
                    <div className="col-content">
                      <h3 className="ttl c-4 has-text-align-center">タイトルタイトル</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-6 floorbc4">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <div className="col-inner">
                    <div className="col-image">
                      <figure className="image">
                        <Image filename="now-printing.jpg" />
                      </figure>

                    </div>
                    <div className="col-content">
                      <h3 className="ttl c-4 has-text-align-center">タイトルタイトル</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-6 floorbc4">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <div className="col-inner">
                    <div className="col-image">
                      <figure className="image">
                        <Image filename="now-printing.jpg" />
                      </figure>

                    </div>
                    <div className="col-content">
                      <h3 className="ttl c-4 has-text-align-center">タイトルタイトル</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-group">
          <Link to="/obm/museum/" className="btn-def no-radius single">
            <span>一覧へ戻る</span>
          </Link>
        </div>
      </section>
      */}
  </Layout>
);

export default Template;
